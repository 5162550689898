import React from 'react';
import ContentBox from './ContentBox';
import images from '../assets/img/Index';
import HeroImg from './HeroImg';

function Birthday() {
  return (
      <main>

          <HeroImg id={'prewedding-hero'} img={images.Birthday} title={'The Wedding Psalm'} tagline={'Birthday'} />

          <section id="prewedding-page" className="prewedding-page">
              <div className="container">
                  <div className="section-header">
                      <h2>Birthday</h2>
                  </div>
                  <div className="row row-cols-1 row-cols-lg-3 g-4 justify-content-center text-center">
                      <ContentBox title={'Dr. Bakaya'} imgUrl={images.Bakaya1} source={'/birthday/dr-bakaya'} />
                      <ContentBox title={'Mrinalini'} imgUrl={images.Mrinalini1} source={'/birthday/mrinalini'} />
                  </div>
                  {/* <div className="section-footer">
                        <button type="button" className="btn">Load more</button>
                    </div> */}
              </div>
          </section>

      </main>
  )
}

export default Birthday