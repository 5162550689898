import React from 'react';
import { Link } from "react-router-dom";

function BlogBox(props) {
    let { date, title, author, imgUrl } = props;
  return (
      <div className="col">
          <div className="card position-relative">
              <div className="card-image">
                  <img src={imgUrl} className="img-fluid" alt="" />
              </div>
              <div className="card-body">
                  <small>{date} By {author}</small>
                  <h3><Link to="/blog/anupreet-and-vibash-a-school-love-story-turned-wedding-bliss" className="nav-link stretched-link linkBtn">{title}</Link></h3>
              </div>
          </div>
      </div>
  )
}

export default BlogBox