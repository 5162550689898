import React from 'react';
import ContentBox from './ContentBox';
import images from '../assets/img/Index';
import HeroImg from './HeroImg';

function Content() {
    return (
        <main>

            <HeroImg id={'wedding-hero'} img={images.Wedding} title={'The Wedding Psalm'} tagline={'Wedding'} />

            <section id="wedding-page" className="wedding-page">
                <div className="container">
                    <div className="section-header">
                        <h2>Wedding</h2>
                    </div>
                    <div className="row row-cols-1 row-cols-lg-3 g-4 justify-content-center text-center">
                        <ContentBox title={'Anirudh Wedding'} imgUrl={images.Anirudh1} source={'/wedding/anirudh-wedding'} />
                        <ContentBox title={'Anupreet Wedding'} imgUrl={images.Anupreet2} source={'/wedding/anupreet-wedding'} />
                        <ContentBox title={'Rohit Manvee Wedding'} imgUrl={images.Rohit1} source={'/wedding/rohit-manvee-wedding'} />
                        <ContentBox title={'Saloni Wedding'} imgUrl={images.Saloni1} source={'/wedding/saloni-wedding'} />
                        <ContentBox title={'Shubham Bhimtal Wedding'} imgUrl={images.Shubham1} source={'/wedding/shubham-bhimtal-wedding'} />
                        </div>
                    {/* <div className="section-footer">
                        <button type="button" className="btn">Load more</button>
                    </div> */}
                </div>
            </section>

        </main>
    )
}

export default Content