import Hero from './hero.webp';
import Logo from './logo.jpg';
import About1 from './about1.jpg';
import About2 from './about2.jpg';
import About3 from './about3.jpg';

// Collage Images
import Collage1 from './collage/collage1.webp';
import Collage2 from './collage/collage2.webp';
import Collage3 from './collage/collage3.webp';
import Collage4 from './collage/collage4.webp';
import Collage5 from './collage/collage5.webp';
import Collage6 from './collage/collage6.webp';
import Collage7 from './collage/collage7.webp';
import Collage8 from './collage/collage8.webp';
import Collage9 from './collage/collage9.webp';
import Collage10 from './collage/collage10.webp';
import Collage11 from './collage/collage11.webp';
import Collage12 from './collage/collage12.webp';

// Reviews Image
import Review1 from './reviews/review1.png';
import Review2 from './reviews/review2.png';
import Review3 from './reviews/review3.png';
import Review4 from './reviews/review4.png';
import Review5 from './reviews/review5.png';

// Page Hero Images
import AboutUs from './about-us.webp';
import Wedding from './wedding.webp';
import Prewedding from './prewedding.webp';
import BadyShower from './bady-shower.webp';
import Birthday from './birthday.webp';
import Cinematography from './cinematography.webp';
import Event from './event.webp';
import EventOrganizer from './event-organizer.webp';
import Gift from './gift.webp';
import Catering from './catering.webp';
import CateringImg from './catering-img.webp';
import Blog from './blog.webp';
import ContactUs from './contact-us.webp';

// Wedding
// Anirudh Wedding Images
import Anirudh1 from './wedding/anirudh/anirudh1.webp';
import Anirudh2 from './wedding/anirudh/anirudh2.webp';
import Anirudh3 from './wedding/anirudh/anirudh3.webp';
import Anirudh4 from './wedding/anirudh/anirudh4.webp';
import Anirudh5 from './wedding/anirudh/anirudh5.webp';
import Anirudh6 from './wedding/anirudh/anirudh6.webp';
import Anirudh7 from './wedding/anirudh/anirudh7.webp';
import Anirudh8 from './wedding/anirudh/anirudh8.webp';
import Anirudh9 from './wedding/anirudh/anirudh9.webp';

// Anupreet Wedding Images
import Anupreet1 from './wedding/anupreet/anupreet1.webp';
import Anupreet2 from './wedding/anupreet/anupreet2.webp';
import Anupreet3 from './wedding/anupreet/anupreet3.webp';
import Anupreet4 from './wedding/anupreet/anupreet4.webp';
import Anupreet5 from './wedding/anupreet/anupreet5.webp';
import Anupreet6 from './wedding/anupreet/anupreet6.webp';
import Anupreet7 from './wedding/anupreet/anupreet7.webp';
import Anupreet8 from './wedding/anupreet/anupreet8.webp';
import Anupreet9 from './wedding/anupreet/anupreet9.webp';
import Anupreet10 from './wedding/anupreet/anupreet10.webp';
import Anupreet11 from './wedding/anupreet/anupreet11.webp';

// Rohit Manvee Wedding Images
import Rohit1 from './wedding/rohit-manvee/rohit1.webp';
import Rohit2 from './wedding/rohit-manvee/rohit2.webp';
import Rohit3 from './wedding/rohit-manvee/rohit3.webp';
import Rohit4 from './wedding/rohit-manvee/rohit4.webp';
import Rohit5 from './wedding/rohit-manvee/rohit5.webp';
import Rohit6 from './wedding/rohit-manvee/rohit6.webp';
import Rohit7 from './wedding/rohit-manvee/rohit7.webp';
import Rohit8 from './wedding/rohit-manvee/rohit8.webp';
import Rohit9 from './wedding/rohit-manvee/rohit9.webp';

// Saloni Wedding Images
import Saloni1 from './wedding/saloni/saloni1.webp';
import Saloni2 from './wedding/saloni/saloni2.webp';
import Saloni3 from './wedding/saloni/saloni3.webp';
import Saloni4 from './wedding/saloni/saloni4.webp';
import Saloni5 from './wedding/saloni/saloni5.webp';
import Saloni6 from './wedding/saloni/saloni6.webp';
import Saloni7 from './wedding/saloni/saloni7.webp';
import Saloni8 from './wedding/saloni/saloni8.webp';

// Shubham Wedding Images
import Shubham1 from './wedding/shubham-bhimtal/shubham1.webp';
import Shubham2 from './wedding/shubham-bhimtal/shubham2.webp';
import Shubham3 from './wedding/shubham-bhimtal/shubham3.webp';
import Shubham4 from './wedding/shubham-bhimtal/shubham4.webp';
import Shubham5 from './wedding/shubham-bhimtal/shubham5.webp';
import Shubham6 from './wedding/shubham-bhimtal/shubham6.webp';
import Shubham7 from './wedding/shubham-bhimtal/shubham7.webp';
import Shubham8 from './wedding/shubham-bhimtal/shubham8.webp';
import Shubham9 from './wedding/shubham-bhimtal/shubham9.webp';
import Shubham10 from './wedding/shubham-bhimtal/shubham10.webp';

// Funstyle Prewedding Images
import Funstyle1 from './prewedding/funstyle-preweding/funstyle1.webp';
import Funstyle2 from './prewedding/funstyle-preweding/funstyle2.webp';
import Funstyle3 from './prewedding/funstyle-preweding/funstyle3.webp';
import Funstyle4 from './prewedding/funstyle-preweding/funstyle4.webp';
import Funstyle5 from './prewedding/funstyle-preweding/funstyle5.webp';
import Funstyle6 from './prewedding/funstyle-preweding/funstyle6.webp';

// Vibhash Prewedding Images
import Vibhash1 from './prewedding/vibhash/vibhash1.webp';
import Vibhash2 from './prewedding/vibhash/vibhash2.webp';
import Vibhash3 from './prewedding/vibhash/vibhash3.webp';
import Vibhash4 from './prewedding/vibhash/vibhash4.webp';
import Vibhash5 from './prewedding/vibhash/vibhash5.webp';
import Vibhash6 from './prewedding/vibhash/vibhash6.webp';

// Baby Shower Images
import Maternity1 from './baby-shower/maternity/maternity1.webp';
import Maternity2 from './baby-shower/maternity/maternity2.webp';
import Maternity3 from './baby-shower/maternity/maternity3.webp';
import Maternity4 from './baby-shower/maternity/maternity4.webp';
import Maternity5 from './baby-shower/maternity/maternity5.webp';
import Maternity6 from './baby-shower/maternity/maternity6.webp';

// Dr. Bakaya Images
import Bakaya1 from './birthday/dr-bakaya/bakaya1.webp';
import Bakaya2 from './birthday/dr-bakaya/bakaya2.webp';
import Bakaya3 from './birthday/dr-bakaya/bakaya3.webp';
import Bakaya4 from './birthday/dr-bakaya/bakaya4.webp';
import Bakaya5 from './birthday/dr-bakaya/bakaya5.webp';
import Bakaya6 from './birthday/dr-bakaya/bakaya6.webp';
import Bakaya7 from './birthday/dr-bakaya/bakaya7.webp';
import Bakaya8 from './birthday/dr-bakaya/bakaya8.webp';

// Mrinalini
import Mrinalini1 from './birthday/mrinalini/mrinalini1.webp';
import Mrinalini2 from './birthday/mrinalini/mrinalini2.webp';
import Mrinalini3 from './birthday/mrinalini/mrinalini3.webp';
import Mrinalini4 from './birthday/mrinalini/mrinalini4.webp';
import Mrinalini5 from './birthday/mrinalini/mrinalini5.webp';
import Mrinalini6 from './birthday/mrinalini/mrinalini6.webp';
import Mrinalini7 from './birthday/mrinalini/mrinalini7.webp';
import Mrinalini8 from './birthday/mrinalini/mrinalini8.webp';
import Mrinalini9 from './birthday/mrinalini/mrinalini9.webp';

// Event Image
import Event1 from './event/event1.webp';
import Event2 from './event/event2.webp';
import Event3 from './event/event3.webp';
import Event4 from './event/event4.webp';
import Event5 from './event/event5.webp';
import Event6 from './event/event6.webp';

// Catering Images
import Catering1 from './catering/catering1.webp';
import Catering2 from './catering/catering2.webp';
import Catering3 from './catering/catering3.webp';
import Catering4 from './catering/catering4.webp';
import Catering5 from './catering/catering5.webp';
import Catering6 from './catering/catering6.webp';
import Catering7 from './catering/catering7.webp';

// Gift Images
import Gift1 from './gift/gift1.webp';
import Gift2 from './gift/gift2.webp';
import Gift3 from './gift/gift3.webp';
import Gift4 from './gift/gift4.webp';
import Gift5 from './gift/gift5.webp';
import Gift6 from './gift/gift6.webp';
import Gift7 from './gift/gift7.webp';
import Gift8 from './gift/gift8.webp';
import Gift9 from './gift/gift9.webp';

// Blog Images
import Avblog1 from './blog/avblog1.webp';
import Avblog2 from './blog/avblog2.webp';
import Avblog3 from './blog/avblog3.webp';
import Avblog4 from './blog/avblog4.webp';
import Avblog5 from './blog/avblog5.webp';
import Avblog6 from './blog/avblog6.webp';
import Avblog7 from './blog/avblog7.webp';
import Avblog8 from './blog/avblog8.webp';
import Avblog9 from './blog/avblog9.webp';
import Avblog10 from './blog/avblog10.webp';
import Avblog11 from './blog/avblog11.webp';
import Avblog12 from './blog/avblog12.webp';

// Icons
import Check from './icon/check.png';
import Cross from './icon/cross.png';
import Warning from './icon/warning.png';

const images = {
    Hero,
    Logo,
    About1,
    About2,
    About3,
    Collage1,
    Collage2,
    Collage3,
    Collage4,
    Collage5,
    Collage6,
    Collage7,
    Collage8,
    Collage9,
    Collage10,
    Collage11,
    Collage12,
    Review1,
    Review2,
    Review3,
    Review4,
    Review5,
    AboutUs,
    Wedding,
    Prewedding,
    BadyShower,
    Birthday,
    Cinematography,
    Event,
    EventOrganizer,
    Event1,
    Event2,
    Event3,
    Event4,
    Event5,
    Event6,
    Gift,
    Gift1,
    Gift2,
    Gift3,
    Gift4,
    Gift5,
    Gift6,
    Gift7,
    Gift8,
    Gift9,
    Catering,
    CateringImg,
    Catering1,
    Catering2,
    Catering3,
    Catering4,
    Catering5,
    Catering6,
    Catering7,
    Blog,
    ContactUs,
    Anirudh1,
    Anirudh2,
    Anirudh3,
    Anirudh4,
    Anirudh5,
    Anirudh6,
    Anirudh7,
    Anirudh8,
    Anirudh9,
    Anupreet1,
    Anupreet2,
    Anupreet3,
    Anupreet4,
    Anupreet5,
    Anupreet6,
    Anupreet7,
    Anupreet8,
    Anupreet9,
    Anupreet10,
    Anupreet11,
    Rohit1,
    Rohit2,
    Rohit3,
    Rohit4,
    Rohit5,
    Rohit6,
    Rohit7,
    Rohit8,
    Rohit9,
    Saloni1,
    Saloni2,
    Saloni3,
    Saloni4,
    Saloni5,
    Saloni6,
    Saloni7,
    Saloni8,
    Shubham1,
    Shubham2,
    Shubham3,
    Shubham4,
    Shubham5,
    Shubham6,
    Shubham7,
    Shubham8,
    Shubham9,
    Shubham10,
    Funstyle1,
    Funstyle2,
    Funstyle3,
    Funstyle4,
    Funstyle5,
    Funstyle6,
    Vibhash1,
    Vibhash2,
    Vibhash3,
    Vibhash4,
    Vibhash5,
    Vibhash6,
    Maternity1,
    Maternity2,
    Maternity3,
    Maternity4,
    Maternity5,
    Maternity6,
    Bakaya1,
    Bakaya2,
    Bakaya3,
    Bakaya4,
    Bakaya5,
    Bakaya6,
    Bakaya7,
    Bakaya8,
    Mrinalini1,
    Mrinalini2,
    Mrinalini3,
    Mrinalini4,
    Mrinalini5,
    Mrinalini6,
    Mrinalini7,
    Mrinalini8,
    Mrinalini9,
    Avblog1,
    Avblog2,
    Avblog3,
    Avblog4,
    Avblog5,
    Avblog6,
    Avblog7,
    Avblog8,
    Avblog9,
    Avblog10,
    Avblog11,
    Avblog12,
    Check,
    Cross,
    Warning,
}
export default images;