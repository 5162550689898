import React from 'react'

function HeroImg(props) {
    let { id, img, title, tagline } = props;
    return (
        <section id={id} className={id}>
            <div className="container-fluid p-0">
                <img src={img} className="img-fluid" alt="" />
                <div className="hero-overlay">
                    <div className="hero-info">
                        <h2 className="animate__animated animate__fadeInUp animate__delay-1s">{title}</h2>
                        <p className="animate__animated animate__fadeInUp animate__delay-1s">{tagline}</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default HeroImg