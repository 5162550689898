import React, { useState } from 'react';
import images from '../assets/img/Index';
import HeroImg from './HeroImg';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
import { Link } from "react-router-dom";
import $ from 'jquery';
import axios from 'axios';

function Index() {
    const [contact, setContact] = useState('');

    const handleContactChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setContact(values => ({ ...values, [name]: value }));
    }

    const handleContactSubmit = (e) => {
        e.preventDefault();
        $("#sendContactMail").html('<span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> Loading...');
        axios.post('https://theweddingpsalm.com/api/contactemail.php/', contact).then(function (response) {
            if (response.data.status === true) {
                $("#sendContactMail").html("Submit Your Query");
                $("#contactForm").trigger("reset");
                $("#successShowBtn").trigger("click");
                $("#successModal p").text(response.data.message);
            } else {
                $("#sendContactMail").html("Submit Your Query");
                $("#contactForm").trigger("reset");
                $("#errorShowBtn").trigger("click");
                $("#errorModal p").text(response.data.message);
            }
        });

    };

    return (
        <>
            <HeroImg id={'hero'} img={images.Hero} title={'Unscripted stories, lasting impressions'} tagline={''} />

            <main>

                <section id="about" className="about">
                    <div className="container">
                        <div className="section-header text-dark d-block d-lg-none">
                            <h2>About Us</h2>
                        </div>
                        <div className="row row-cols-1 row-cols-lg-2 gx-5">
                            <div className="col p-0">
                                <div className="about-image">
                                    <img src={images.About1} className="img-fluid image-one" loading="lazy" alt="" />
                                    <img src={images.About2} className="img-fluid image-two" loading="lazy" alt="" />
                                    <img src={images.About3} className="img-fluid image-three" loading="lazy" alt="" />
                                    <div className="shadow"></div>
                                </div>
                            </div>
                            <div className="col align-self-center">
                                <div className="about-content">
                                    <h2 className="d-none d-lg-block">About Us</h2>
                                    <p>Hello, I'm Mohit, the creative force behind The Wedding Psalm. My journey from Spanish enthusiast to MBA graduate led me unexpectedly to photography.</p>
                                    <p>Starting as a college curiosity, I dabbled in Spanish, exploring the beauty of language. An MBA followed, delving into the intricacies of business dynamics. Amidst this, photography quietly found its way into my heart.</p>
                                    <Link to="/about" className="btn linkBtn">Explore More</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="collage-image" className="collage-image">
                    <div className="container-fluid p-0">
                        <div className="row g-0">
                            <div className="col-12 col-lg-7">
                                <div className="row g-0">
                                    <div className="col-6">
                                        <div className="image">
                                            <img src={images.Collage1} className="img-fluid" loading="lazy" alt="" />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="image">
                                            <img src={images.Collage2} className="img-fluid" loading="lazy" alt="" />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="image">
                                            <img src={images.Collage3} className="img-fluid" loading="lazy" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-5">
                                <div className="row g-0">
                                    <div className="col-12">
                                        <div className="image">
                                            <img src={images.Collage4} className="img-fluid" loading="lazy" alt="" />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="image">
                                            <img src={images.Collage5} className="img-fluid" loading="lazy" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row g-0">
                            <div className="col-12 col-lg-4">
                                <div className="row g-0">
                                    <div className="col-12">
                                        <div className="image">
                                            <img src={images.Collage6} className="img-fluid" loading="lazy" alt="" />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="image">
                                            <img src={images.Collage7} className="img-fluid" loading="lazy" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-5">
                                <div className="row g-0">
                                    <div className="col-12">
                                        <div className="image">
                                            <img src={images.Collage8} className="img-fluid" loading="lazy" alt="" />
                                        </div>
                                    </div>
                                    <div className="col-5">
                                        <div className="image">
                                            <img src={images.Collage9} className="img-fluid" loading="lazy" alt="" />
                                        </div>
                                    </div>
                                    <div className="col-7">
                                        <div className="image">
                                            <img src={images.Collage10} className="img-fluid" loading="lazy" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-3">
                                <div className="row g-0">
                                    <div className="col-12">
                                        <div className="image">
                                            <img src={images.Collage11} className="img-fluid" loading="lazy" alt="" />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="image">
                                            <img src={images.Collage12} className="img-fluid" loading="lazy" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="reviews" className="reviews">
                    <div className="container">
                        <div className="section-header">
                            <h2>Why Choose The Wedding Psalm</h2>
                            <p>Your words, our inspiration - Hear what our customers say and join the satisfied voices<br className="d-none d-lg-flex" />that fuel our passion for excellence.</p>
                        </div>

                        <Swiper
                            className="swipper mySwiper"
                            slidesPerView={1}
                            spaceBetween={30}
                            pagination={{
                                clickable: true,
                            }}
                            modules={[Autoplay, Pagination]}
                            breakpoints={{
                                768: {
                                    slidesPerView: 2,
                                },
                                992: {
                                    slidesPerView: 3,
                                },
                            }}
                            autoplay={{
                                delay: 2000,
                                disableOnInteraction: false,
                            }}
                        >
                            <SwiperSlide className="swiper-slide">
                                <div className="review-item">
                                    <div className="review-img">
                                        <img src={images.Review1} className="img-fluid" loading="lazy" alt="" />
                                    </div>
                                    <h3>Nikita Sharma</h3>
                                    <ul className="nav justify-content-center gap-1">
                                        <li><i className="fa-solid fa-star"></i></li>
                                        <li><i className="fa-solid fa-star"></i></li>
                                        <li><i className="fa-solid fa-star"></i></li>
                                        <li><i className="fa-solid fa-star"></i></li>
                                        <li><i className="fa-solid fa-star"></i></li>
                                    </ul>
                                    <div className="review-content">
                                        <p>I am a fan of his work. Has seen the work in my friend's wedding. And already booked him for mine. It's different and so amazing...</p>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="swiper-slide">
                                <div className="review-item">
                                    <div className="review-img">
                                        <img src={images.Review2} className="img-fluid" loading="lazy" alt="" />
                                    </div>
                                    <h3>Isha Soni</h3>
                                    <ul className="nav justify-content-center gap-1">
                                        <li><i className="fa-solid fa-star"></i></li>
                                        <li><i className="fa-solid fa-star"></i></li>
                                        <li><i className="fa-solid fa-star"></i></li>
                                        <li><i className="fa-solid fa-star"></i></li>
                                        <li><i className="fa-solid fa-star"></i></li>
                                    </ul>
                                    <div className="review-content">
                                        <p>Wedding Pslam is an Amazing bunch of talented guys ! They exactly know what a customer needs . “You guys are ab-sol-utely mind blowing...</p>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="swiper-slide">
                                <div className="review-item">
                                    <div className="review-img">
                                        <img src={images.Review3} className="img-fluid" loading="lazy" alt="" />
                                    </div>
                                    <h3>Keshav Bhardwaj</h3>
                                    <ul className="nav justify-content-center gap-1">
                                        <li><i className="fa-solid fa-star"></i></li>
                                        <li><i className="fa-solid fa-star"></i></li>
                                        <li><i className="fa-solid fa-star"></i></li>
                                        <li><i className="fa-solid fa-star"></i></li>
                                        <li><i className="fa-solid fa-star"></i></li>
                                    </ul>
                                    <div className="review-content">
                                        <p>I booked these guys for my brothers wedding! I’ve been so pleasantly surprised by Mohit’s work. He & his team are very professional...</p>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="swiper-slide">
                                <div className="review-item">
                                    <div className="review-img">
                                        <img src={images.Review4} className="img-fluid" loading="lazy" alt="" />
                                    </div>
                                    <h3>Maheshanand Upreti</h3>
                                    <ul className="nav justify-content-center gap-1">
                                        <li><i className="fa-solid fa-star"></i></li>
                                        <li><i className="fa-solid fa-star"></i></li>
                                        <li><i className="fa-solid fa-star"></i></li>
                                        <li><i className="fa-solid fa-star"></i></li>
                                        <li><i className="fa-solid fa-star"></i></li>
                                    </ul>
                                    <div className="review-content">
                                        <p>It's been an year the wedding psalm took pictures on our special day. Till date me and my family cherish the day with the pictures that you guys...</p>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="swiper-slide">
                                <div className="review-item">
                                    <div className="review-img">
                                        <img src={images.Review5} className="img-fluid" loading="lazy" alt="" />
                                    </div>
                                    <h3>Meemansha Mahtoliya</h3>
                                    <ul className="nav justify-content-center gap-1">
                                        <li><i className="fa-solid fa-star"></i></li>
                                        <li><i className="fa-solid fa-star"></i></li>
                                        <li><i className="fa-solid fa-star"></i></li>
                                        <li><i className="fa-solid fa-star"></i></li>
                                        <li><i className="fa-solid fa-star"></i></li>
                                    </ul>
                                    <div className="review-content">
                                        <p>The Wedding Psalm holds a special place in our hearts for their heartfelt and meaningful photography. Their dedicated team...</p>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <div className="swiper-pagination"></div>
                        </Swiper>

                        <div className="section-footer">
                            <Link to="https://maps.app.goo.gl/d1gJNDQND6b7TscK9" target="_blank" type="button" className="btn">More reviews</Link>
                        </div>
                    </div>
                </section>

                <section id="contact" className="contact">
                    <div className="container-fluid">
                        <div className="row justify-content-end align-items-center">
                            <div className="col-12 col-lg-4 p-0">
                                <div className="contact-form">
                                    {/* <form className="contact-form" onSubmit={sendEmail}> */}
                                    <form onSubmit={handleContactSubmit} id="contactForm" className="contact-form">
                                        <div className="row">
                                            <h2>Contact Us</h2>
                                        </div>
                                        <div className="row row-cols-1 g-3 justify-content-center">
                                            <div className="col">
                                                <div className="form-floating">
                                                    <input type="text" className="form-control" id="cname" name="cname" onChange={handleContactChange} placeholder="Name" required />
                                                    <label htmlFor="cname">Name</label>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="form-floating">
                                                    <input type="email" className="form-control" id="cemail" name="cemail" onChange={handleContactChange} placeholder="Email address" required />
                                                    <label htmlFor="cemail">Email address</label>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="form-floating">
                                                    <input type="tel" className="form-control" id="cphone" name="cphone" onChange={handleContactChange} placeholder="Phone" required />
                                                    <label htmlFor="cphone">Phone</label>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="form-floating">
                                                    <input type="text" className="form-control" id="csubject" name="csubject" onChange={handleContactChange} placeholder="Subject" required />
                                                    <label htmlFor="csubject">Subject</label>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="form-floating">
                                                    <textarea className="form-control" onChange={handleContactChange} placeholder="Message" id="cmessage" name="cmessage" style={{ height: "100px" }} required></textarea>
                                                    <label htmlFor="cmessage">Message</label>
                                                </div>
                                            </div>
                                            <div className="col-12 d-grid">
                                                <button type="submit" className="btn" id="sendContactMail">Submit</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="col-1"></div>
                            <div className="col-12 col-lg-6">
                            </div>
                        </div>
                    </div>
                </section>

            </main>
        </>
    )
}

export default Index