import React from 'react';
import images from '../assets/img/Index';
import HeroImg from './HeroImg';

function Event() {
    return (
        <main>

            <HeroImg id={'event-hero'} img={images.Catering} title={'The Wedding Psalm'} tagline={'Catering'} />

            <section id="event-page" className="event-page">
                <div className="container">
                    <div className="section-header">
                        <h2>Catering</h2>
                    </div>
                    <div className="row g-5">
                        <div className="col-12 col-lg-5">
                            <div className="image">
                                <img src={images.CateringImg} className="img-fluid" alt=""></img>
                            </div>
                        </div>
                        <div className="col-12 col-lg-7 align-self-center">
                            <h3>CREATIVE CATERING FOR SPECIAL EVENTS</h3>
                            <p>As a catering company, we are dedicated to serving your guests in the most sophisticated and personable manner, and have been doing so for over 30 years as a family owned business, previously named Mohit caterers. Was built as a brand that would epitomise the best of world cuisine and match it with the highest standards of service.</p>
                            <div className="row row-cols-1 row-cols-lg-2 g-2 mt-4">
                                <div className="col">
                                    <div className="box">
                                        <i className="fa-solid fa-circle-check"></i>
                                        <small>Weddings & Engagements</small>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="box">
                                        <i className="fa-solid fa-circle-check"></i>
                                        <small>Showers</small>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="box">
                                        <i className="fa-solid fa-circle-check"></i>
                                        <small>Birthdays</small>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="box">
                                        <i className="fa-solid fa-circle-check"></i>
                                        <small>Business events</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr className="my-5" />
                    <div className="row row-cols-1 row-cols-lg-3 g-3">
                        <div className="col">
                            <div className="image">
                                <img src={images.Catering1} className="img-fluid" alt=""></img>
                            </div>
                        </div>
                        <div className="col">
                            <div className="image">
                                <img src={images.Catering2} className="img-fluid" alt=""></img>
                            </div>
                        </div>
                        <div className="col">
                            <div className="image">
                                <img src={images.Catering3} className="img-fluid" alt=""></img>
                            </div>
                        </div>
                        <div className="col">
                            <div className="image">
                                <img src={images.Catering4} className="img-fluid" alt=""></img>
                            </div>
                        </div>
                        <div className="col">
                            <div className="image">
                                <img src={images.Catering5} className="img-fluid" alt=""></img>
                            </div>
                        </div>
                        <div className="col">
                            <div className="image">
                                <img src={images.Catering6} className="img-fluid" alt=""></img>
                            </div>
                        </div>
                    </div>
                    {/* <div className="section-footer">
                        <button type="button" className="btn">Load more</button>
                    </div> */}
                </div>
            </section>

        </main>
    )
}

export default Event