import React from 'react';
import images from '../assets/img/Index';
import HeroImg from './HeroImg';

function Gift() {
  return (
    <main>

      <HeroImg id={'event-hero'} img={images.Gift} title={'The Wedding Psalm'} tagline={'Gift'} />

      <section id="event-page" className="event-page">
        <div className="container">
          <div className="section-header">
            <h2>Gift</h2>
          </div>
          <div className="row row-cols-1 row-cols-lg-3 g-3">
            <div className="col">
              <div className="image">
                <img src={images.Gift1} className="img-fluid" alt=""></img>
              </div>
            </div>
            <div className="col">
              <div className="image">
                <img src={images.Gift2} className="img-fluid" alt=""></img>
              </div>
            </div>
            <div className="col">
              <div className="image">
                <img src={images.Gift3} className="img-fluid" alt=""></img>
              </div>
            </div>
            <div className="col">
              <div className="image">
                <img src={images.Gift4} className="img-fluid" alt=""></img>
              </div>
            </div>
            <div className="col">
              <div className="image">
                <img src={images.Gift5} className="img-fluid" alt=""></img>
              </div>
            </div>
            <div className="col">
              <div className="image">
                <img src={images.Gift6} className="img-fluid" alt=""></img>
              </div>
            </div>
            <div className="col">
              <div className="image">
                <img src={images.Gift7} className="img-fluid" alt=""></img>
              </div>
            </div>
            <div className="col">
              <div className="image">
                <img src={images.Gift8} className="img-fluid" alt=""></img>
              </div>
            </div>
            <div className="col">
              <div className="image">
                <img src={images.Gift9} className="img-fluid" alt=""></img>
              </div>
            </div>
          </div>
          {/* <div className="section-footer">
                        <button type="button" className="btn">Load more</button>
                    </div> */}
        </div>
      </section>

    </main>
  )
}

export default Gift