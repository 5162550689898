import React from 'react';
import HeroImg from './HeroImg';

function Images(props) {
    const seasons = props.WedImage;
    return (
        <main>

            <HeroImg id={'image-hero'} img={props.WedHero} />

            <section id="image-page" className="image-page">
                <div className="container">
                    <div className="section-header">
                        <h2>{props.WedTitle}</h2>
                    </div>
                    <div className="row row-cols-1 row-cols-lg-3 g-2 d-flex">
                        {seasons.map((season) => (
                            <div className="col">
                                <div className="card">
                                    <img src={season} className="img-fluid" alt="" />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

        </main>
    )
}

export default Images