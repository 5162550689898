import React from 'react';
import images from '../assets/img/Index';
import HeroImg from './HeroImg';

function About() {
  return (
      <main>

          <HeroImg id={'wedding-hero'} img={images.AboutUs} title={'The Wedding Psalm'} tagline={'About Us'} />

          <section id="wedding-page" className="wedding-page">
              <div className="container">
                  <div className="section-header">
                      <h2>About Us</h2>
                  </div>
                  <div className="row text-center">
                      <h2>From Passion to Purpose</h2>
                      <p>Hello, I'm Mohit, the creative force behind Wedding Psalm. My journey from Spanish enthusiast to MBA graduate led me unexpectedly to photography.</p>
                      <h3>Unexpected Path, Serendipitous Passion</h3>
                      <p>Starting as a college curiosity, I dabbled in Spanish, exploring the beauty of language. An MBA followed, delving into the intricacies of business dynamics. Amidst this, photography quietly found its way into my heart.</p>
                      <p>Weddings, with their unrepeatable moments, became my canvas. Imperfections held the real magic, deserving to be frozen in time – the tears, laughter, and stolen glances.</p>
                      <h3>Crafting Everlasting Love Stories</h3>
                      <p>My calling is clear: to craft stories, immortalize emotions, and create enduring memories.</p>
                      <p>Join me in celebrating imperfect beauty, candid elegance, and the enchantment of love stories.</p>
                  </div>
              </div>
          </section>

      </main>
  )
}

export default About