import './assets/sass/main.css';
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Footer from './components/Footer';
import Index from './components/Index';
import Contact from './components/Contact';
import About from './components/About';
import Event from './components/Event';
import Gift from './components/Gift';
import Wedding from './components/Wedding';
import Prewedding from './components/Prewedding';
import Badyshowers from './components/Babyshowers';
import Cinematography from './components/Cinematography';
import Blog from './components/Blog';
import Single from './components/Single';
import Images from './components/Images';
import images from './assets/img/Index';
import './assets/js/main';
import ScrollToTop from './components/ScrollToTop';
import Birthday from './components/Birthday';
import Catering from './components/Catering';

function App() {
  return (
    <div>
      <Router>
        <ScrollToTop />
        <Navbar />
        <Routes>
          <Route exact path="/" element={<Index />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/prewedding" element={<Prewedding />} />
          <Route exact path="/wedding" element={<Wedding />} />
          <Route exact path="/baby-shower" element={<Badyshowers />} />
          <Route exact path="/birthday" element={<Birthday />} />
          <Route exact path="/cinematography" element={<Cinematography />} />
          <Route exact path="/event" element={<Event />} />
          <Route exact path="/gift" element={<Gift />} />
          <Route exact path="/catering" element={<Catering />} />
          <Route exact path="/blog" element={<Blog />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/blog/anupreet-and-vibash-a-school-love-story-turned-wedding-bliss" element={<Single />} />
          <Route exact path="/wedding/anirudh-wedding" element={<Images WedHero={images.Anirudh1} WedTitle={"Anirudh Wedding"} WedImage={[images.Anirudh1, images.Anirudh2, images.Anirudh3, images.Anirudh4, images.Anirudh5, images.Anirudh6, images.Anirudh7, images.Anirudh8, images.Anirudh9]} />} />
          <Route exact path="/wedding/anupreet-wedding" element={<Images WedHero={images.Anupreet1} WedTitle={"Anupreet Wedding"} WedImage={[images.Anupreet1, images.Anupreet2, images.Anupreet3, images.Anupreet4, images.Anupreet5, images.Anupreet6, images.Anupreet7, images.Anupreet8, images.Anupreet9, images.Anupreet10, images.Anupreet11]} />} />
          <Route exact path="/wedding/rohit-manvee-wedding" element={<Images WedHero={images.Rohit1} WedTitle={"Rohit Manvee Wedding"} WedImage={[images.Rohit1, images.Rohit2, images.Rohit3, images.Rohit4, images.Rohit5, images.Rohit6, images.Rohit7, images.Rohit8, images.Rohit9]} />} />
          <Route exact path="/wedding/saloni-wedding" element={<Images WedHero={images.Saloni1} WedTitle={"Saloni Wedding"} WedImage={[images.Saloni1, images.Saloni2, images.Saloni3, images.Saloni4, images.Saloni5, images.Saloni6, images.Saloni7, images.Saloni8]} />} />
          <Route exact path="/wedding/shubham-bhimtal-wedding" element={<Images WedHero={images.Shubham1} WedTitle={"Shubham Bhimtal Wedding"} WedImage={[images.Shubham1, images.Shubham2, images.Shubham3, images.Shubham4, images.Shubham5, images.Shubham6, images.Shubham7, images.Shubham8, images.Shubham9, images.Shubham10]} />} />
          <Route exact path="/prewedding/funstyle-preweding" element={<Images WedHero={images.Funstyle1} WedTitle={"Funstyle Preweding"} WedImage={[images.Funstyle1, images.Funstyle2, images.Funstyle3, images.Funstyle4, images.Funstyle5, images.Funstyle6]} />} />
          <Route exact path="/prewedding/vibhash" element={<Images WedHero={images.Vibhash2} WedTitle={"Vibhash"} WedImage={[images.Vibhash1, images.Vibhash2, images.Vibhash3, images.Vibhash4, images.Vibhash5, images.Vibhash6]} />} />
          <Route exact path="/baby-shower/maternity" element={<Images WedHero={images.Maternity1} WedTitle={"Maternity"} WedImage={[images.Maternity1, images.Maternity2, images.Maternity3, images.Maternity4, images.Maternity5, images.Maternity6]} />} />
          <Route exact path="/birthday/dr-bakaya" element={<Images WedHero={images.Bakaya1} WedTitle={"Bakaya"} WedImage={[images.Bakaya1, images.Bakaya2, images.Bakaya3, images.Bakaya4, images.Bakaya5, images.Bakaya6, images.Bakaya7, images.Bakaya8]} />} />
          <Route exact path="/birthday/mrinalini" element={<Images WedHero={images.Mrinalini1} WedTitle={"Mrinalini"} WedImage={[images.Mrinalini1, images.Mrinalini2, images.Mrinalini3, images.Mrinalini4, images.Mrinalini5, images.Mrinalini6, images.Mrinalini7, images.Mrinalini8, images.Mrinalini9]} />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
