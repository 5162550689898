import React from 'react';
import { Link } from "react-router-dom";

function contentItem(props) {
    let { title, imgUrl, source } = props;
    return (
        <div className="col">
            <div className="card position-relative">
                <div className="card-image">
                    <img src={imgUrl} className="img-fluid" alt="" />
                </div>
                <div className="card-body">
                    <h3><Link to={source} className="nav-link stretched-link linkBtn">{title}</Link></h3>
                </div>
            </div>
        </div >
    )
}

export default contentItem