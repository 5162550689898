import React from 'react'

function CineBox(props) {
    let { source, title } = props;
    return (
        <div className="col">
            <div className="card">
                <iframe src={source} title={title} frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
            </div>
        </div>
    )
}

export default CineBox