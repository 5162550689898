import React from 'react';
import images from '../assets/img/Index';
import HeroImg from './HeroImg';

function Single() {
    return (
        <main>

            <HeroImg id={'single-hero'} img={images.Blog} />

            <section id="single-page" className="single-page">
                <div className="container">
                    <div className="section-header">
                        <h2>Anupreet and Vibash: A School Love Story Turned Wedding Bliss</h2>
                    </div>
                    <div className="row">
                        <h3>Introduction:</h3>
                        <p>Every love story is unique, but some stand out for their authenticity and charm. Anupreet and Vibash's journey from high school sweethearts to newlyweds is one such heartwarming tale that we had the privilege of capturing through our lenses. Their love story is a testament to the power of love, and their wedding was a beautiful fusion of two distinct cultures, Sikh and Hindu Garhwali. Join us as we delve into the enchanting love story of Anupreet and Vibash, and their stunning wedding celebrations at LP Villas.</p>
                        <h3>Chapter 1: A School Love Story</h3>
                        <img src={images.Avblog4} className="img-fluid" alt="" />
                        <p>Anupreet and Vibash's love story began in the hallways of their school. It was a classic tale of two young hearts finding solace in each other's company amidst the chaos of exams and classroom chatter. Their friendship soon blossomed into a beautiful romance that stood the test of time. From sharing lunchboxes to stealing glances during lectures, their love story was a testament to the enduring power of school-time crushes.</p>
                        <h3>Chapter 2: Pre-Wedding Photography in School</h3>
                        <img src={images.Avblog5} className="img-fluid" alt="" />
                        <p>To celebrate the journey of their love, we had the privilege of capturing their pre-wedding moments right where it all began – their school. The nostalgic backdrop of their school created a perfect canvas for their pre-wedding photoshoot. Anupreet and Vibash's chemistry was palpable, and their love shone through every frame. It was a joy to witness their love story come full circle in such a unique way.</p>
                        <h3>Chapter 3: The Wedding at LP Villas</h3>
                        <img src={images.Avblog2} className="img-fluid" alt="" />
                        <p>The wedding of Anupreet and Vibash was a testament to their love and the coming together of two beautiful souls. Held at the exquisite LP Villas, their wedding was a blend of elegance and simplicity. What struck us the most was the contrast between the sophisticated bride, Anupreet, and the laid-back, cool vibes of Vibash. It was a match made in heaven, and we couldn't have been happier to capture their special day.</p>
                        <h3>Chapter 4: The Enchanting Bride</h3>
                        <img src={images.Avblog3} className="img-fluid" alt="" />
                        <p>Anupreet, the bride, looked absolutely stunning in her wedding attire. She chose a soft pastel pink color that complemented the overall wedding theme perfectly. The simplicity of her attire added to the elegance of the occasion. It's always a pleasure to work with couples who pay attention to the details, as it reflects in the beauty of the photographs.</p>
                        <h3>Chapter 5: A Fusion of Cultures</h3>
                        <img src={images.Avblog1} className="img-fluid" alt="" />
                        <p>Anupreet and Vibash's wedding was not just a celebration of their love but also a harmonious fusion of their two different cultures. The Sikh and Hindu Garhwali traditions came together seamlessly, highlighting the unity that love brings. The wedding was a beautiful blend of rituals and customs, and it all went off without a hitch.</p>
                        <h3>Conclusion:</h3>
                        <p>Anupreet and Vibash's love story is a reminder that love knows no boundaries, and when it's meant to be, it will find a way. Their wedding at LP Villas was a testament to their love's authenticity, and we were honored to be a part of their special day. The beautiful bride, the cool groom, and the fusion of cultures created a wedding to remember. We wish Anupreet and Vibash a lifetime filled with love, laughter, and beautiful memories. Thank you for letting us be a part of your story.</p>
                        <p>If you're looking for a wedding photographer to capture your special moments, please don't hesitate to reach out to us. We'd be delighted to tell your unique love story through our lens</p>
                        <div className="row row-cols-2 row-cols-lg-3 g-2">
                            <div className="col">
                                <div className="card">
                                    <img src={images.Avblog7} className="img-fluid" alt="" />
                                </div>
                            </div>
                            <div className="col">
                                <div className="card">
                                    <img src={images.Avblog8} className="img-fluid" alt="" />
                                </div>
                            </div>
                            <div className="col">
                                <div className="card">
                                    <img src={images.Avblog9} className="img-fluid" alt="" />
                                </div>
                            </div>
                            <div className="col">
                                <div className="card">
                                    <img src={images.Avblog10} className="img-fluid" alt="" />
                                </div>
                            </div>
                            <div className="col">
                                <div className="card">
                                    <img src={images.Avblog11} className="img-fluid" alt="" />
                                </div>
                            </div>
                            <div className="col">
                                <div className="card">
                                    <img src={images.Avblog12} className="img-fluid" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </main>
    )
}

export default Single