import React from 'react';
import images from '../assets/img/Index';
import $ from 'jquery';

function Footer() {

    const bottomToTop = () => {
        $(window).scrollTop(0);
    }

    return (
        <>
            {/* Footer Start */}
            <footer id="footer" className="footer">
                <div className="container">
                    <div className="row g-4">
                        <div className="col-auto">
                            <div className="hstack d-block d-lg-flex text-center text-lg-start">
                                <img src={images.Logo} className="img-fluid rounded-circle me-2" width="50px" alt="The Wedding Psalm" />
                                <b className="fs-4">The Wedding Psalm</b>
                                <div className="d-none d-lg-flex mx-2" style={{ height: "30px" }}>
                                    <div className="vr"></div>
                                </div>
                                <small>Copyright © 2023 The Wedding Psalm Made By Web Durga</small>
                            </div>
                        </div>
                        <div className="col d-none d-lg-block">
                            <ul className="nav flex-row justify-content-end align-items-center">
                                <li className="nav-item"><a rel="noreferrer" href="https://www.facebook.com/theweddingpsalmDehradun" target="_blank" aria-label="Facebook" className="nav-link"><i className="fa-brands fa-facebook"></i></a></li>
                                <li className="nav-item"><a rel="noreferrer" href="https://www.instagram.com/_theweddingpsalm_/" target="_blank" aria-label="Instagram" className="nav-link"><i className="fa-brands fa-instagram"></i></a></li>
                                <li className="nav-item"><a rel="noreferrer" href="https://www.youtube.com/channel/UCSWhMr0pbNCURsUomczBeOw" target="_blank" aria-label="YouTube" className="nav-link"><i className="fa-brands fa-youtube"></i></a></li>
                                <li className="nav-item"><a rel="noreferrer" href="https://goo.gl/maps/mu2s6dBVG83A2Qjz6" target="_blank" aria-label="Google Map" className="nav-link"><i className="fa-brands fa-google"></i></a></li>
                                <li className="nav-item"><a rel="noreferrer" href="tel:+4733378901" target="_blank" aria-label="Call" className="nav-link"><i className="fa-solid fa-phone"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
            {/* Footer End */}

            {/* Social Media Icon */}
            <div id="social-media" className="social-media d-none d-md-flex" data-aos="fade-left">
                <ul className="nav flex-column text-center">
                    <li className="nav-item"><a rel="noreferrer" href="https://www.facebook.com/theweddingpsalmDehradun" target="_blank" aria-label="Facebook" className="nav-link border-bottom border-light"><i className="fa-brands fa-facebook"></i></a></li>
                    <li className="nav-item"><a rel="noreferrer" href="https://www.instagram.com/_theweddingpsalm_/" target="_blank" aria-label="Instagram" className="nav-link border-bottom border-light"><i className="fa-brands fa-instagram"></i></a></li>
                    <li className="nav-item"><a rel="noreferrer" href="https://www.youtube.com/channel/UCSWhMr0pbNCURsUomczBeOw" target="_blank" aria-label="YouTube" className="nav-link border-bottom border-light"><i className="fa-brands fa-youtube"></i></a></li>
                    <li className="nav-item"><a rel="noreferrer" href="https://goo.gl/maps/mu2s6dBVG83A2Qjz6" target="_blank" aria-label="Google Map" className="nav-link border-bottom border-light"><i className="fa-brands fa-google"></i></a></li>
                    <li className="nav-item"><a rel="noreferrer" href="tel:+919760439845" target="_blank" aria-label="Call" className="nav-link"><i className="fa-solid fa-phone"></i></a></li>
                </ul>
            </div>
            <div id="social-media-mobile" className="social-media-mobile d-flex d-md-none" data-aos="fade-left">
                <ul className="nav flex-row nav-fill text-center justify-content-center">
                    <li className="nav-item"><a rel="noreferrer" href="https://www.facebook.com/theweddingpsalmDehradun" target="_blank" aria-label="Facebook" className="nav-link border-end border-light"><i className="fa-brands fa-facebook"></i></a></li>
                    <li className="nav-item"><a rel="noreferrer" href="https://www.instagram.com/_theweddingpsalm_/" target="_blank" aria-label="Instagram" className="nav-link border-end border-light"><i className="fa-brands fa-instagram"></i></a></li>
                    <li className="nav-item"><a rel="noreferrer" href="https://wa.me/+919760439845" target="_blank" aria-label="WhatsApp" className="nav-link border-end border-light"><i className="fa-brands fa-whatsapp"></i></a></li>
                    <li className="nav-item"><a rel="noreferrer" href="https://www.youtube.com/channel/UCSWhMr0pbNCURsUomczBeOw" target="_blank" aria-label="YouTube" className="nav-link border-end border-light"><i className="fa-brands fa-youtube"></i></a></li>
                    <li className="nav-item"><a rel="noreferrer" href="tel:+919760439845" target="_blank" aria-label="Call" className="nav-link"><i className="fa-solid fa-phone"></i></a></li>
                </ul>
            </div>

            {/* WhatsApp Icon */}
            <a href="https://wa.me/+919760439845" className="nav-link d-none d-md-flex" target="_blank" aria-label="WhatsApp" id="whatsapp-icon"><i className="fa-brands fa-whatsapp"></i></a>
            <span id="bottomToTopScroll" onClick={bottomToTop}><i className="fa-solid fa-angle-up"></i></span>

            {/* Show Message */}
            <button type="button" className="btn btn-primary d-none" id="successShowBtn" data-bs-toggle="modal" data-bs-target="#successModal"></button>
            <button type="button" className="btn btn-primary d-none" id="errorShowBtn" data-bs-toggle="modal" data-bs-target="#errorModal"></button>

            <div className="modal fade" id="successModal" tabIndex="-1" aria-labelledby="successModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body p-5 text-center">
                            <img src={images.Check} className="img-fluid rounded-circle" width="80px" alt="" />
                            <p className="fs-5 mt-2 mb-4"></p>
                            <button type="button" className="btn btn-primary py- px-5" data-bs-dismiss="modal">Okay</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="errorModal" tabIndex="-1" aria-labelledby="errorModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body p-5 text-center">
                            <img src={images.Cross} className="img-fluid rounded-circle" width="80px" alt="" />
                            <p className="fs-5 mt-2 mb-4"></p>
                            <button type="button" className="btn btn-primary py- px-5" data-bs-dismiss="modal">Okay</button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Footer