import React from 'react';
import images from '../assets/img/Index';
import HeroImg from './HeroImg';
import Cinematography from './CineBox';

function Content() {
    return (
        <main>

            <HeroImg id={'cinematography-hero'} img={images.Cinematography} title={'The Wedding Psalm'} tagline={'Cinematography'} />

            <section id="cinematography-page" className="cinematography-page">
                <div className="container">
                    <div className="section-header">
                        <h2>Cinematography</h2>
                    </div>
                    <div className="row row-cols-1 row-cols-lg-2 g-4">
                        <Cinematography source={'https://www.youtube.com/embed/AwrCJwxzRok'} title={'Cinematic Wedding Film of Vibhor and Somya!'} />
                        <Cinematography source={'https://www.youtube.com/embed/dSTbL5UB85s'} title={'Vibhor and Somya wedding teaser !'} />
                        <Cinematography source={'https://www.youtube.com/embed/hW_gPkhboV8'} title={'Wedding invitation: Raghav and Kanika'} />
                        <Cinematography source={'https://www.youtube.com/embed/AfxXzyVN5yo'} title={'Vibhor and Somya Wedding invitation !'} />
                        <Cinematography source={'https://www.youtube.com/embed/ih-58Bo9uLI'} title={'Vibhor and Somya Wedding invitation !'} />
                        <Cinematography source={'https://www.youtube.com/embed/10cc0eQv1Qg'} title={'Vibhor and Somya Wedding invitation !'} />
                    </div>
                    <div className="section-footer">
                        <a href="https://www.youtube.com/channel/UCSWhMr0pbNCURsUomczBeOw" target="_blank" className="btn">View More</a>
                    </div>
                </div>
            </section>

        </main>
    )
}

export default Content