import React from 'react';
import images from '../assets/img/Index';
import HeroImg from './HeroImg';

function Contact() {
    return (
        <main>

            <HeroImg id={'contact-hero'} img={images.ContactUs} title={'The Wedding Psalm'} tagline={'Contact Us'} />

            <section id="contact-page" className="contact-page">
                <div className="container">
                    <div className="section-header">
                        <h2>Contact Us</h2>
                    </div>
                    <div className="row row-cols-1 row-cols-lg-2 g-4 align-items-center">
                        <div className="col">
                            <div className="contact-info">
                                <div className="row row-cols-1 g-4 align-items-center">
                                    <div className="col">
                                        <div className="detail">
                                            <h3>Phone:</h3>
                                            <p>+91-5412556845</p>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="detail">
                                            <h3>Email address:</h3>
                                            <p>theweddingpsalm@gmail.com</p>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="detail">
                                            <h3>Location:</h3>
                                            <p>11 Kandoli Canal Road, Road, Rajpur, Dehradun, Uttarakhand 248001</p>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="detail">
                                            <h3>Social Media:</h3>
                                            <ul className="nav">
                                                <li className="nav-item"><a href="" className="nav-link"><i className="fa-brands fa-facebook"></i></a></li>
                                                <li className="nav-item"><a href="" className="nav-link"><i className="fa-brands fa-instagram"></i></a></li>
                                                <li className="nav-item"><a href="" className="nav-link"><i className="fa-brands fa-youtube"></i></a></li>
                                                <li className="nav-item"><a href="" className="nav-link"><i className="fa-brands fa-google"></i></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="contactForm">
                                <form action="">
                                    <div className="row row-cols-1 g-4">
                                        <div className="col">
                                            <div className="form-floating">
                                                <input type="email" className="form-control" id="floatingInput" placeholder="name@example.com" />
                                                <label htmlFor="floatingInput">Name</label>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="form-floating">
                                                <input type="email" className="form-control" id="floatingInput" placeholder="name@example.com" />
                                                <label htmlFor="floatingInput">Email address</label>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="form-floating">
                                                <input type="email" className="form-control" id="floatingInput" placeholder="name@example.com" />
                                                <label htmlFor="floatingInput">Phone</label>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="form-floating">
                                                <textarea className="form-control" placeholder="Leave a comment here" id="floatingTextarea2" style={{ height: "150px" }}></textarea>
                                                <label htmlFor="floatingTextarea2">Message</label>
                                            </div>
                                        </div>
                                        <div className="col text-center d-grid">
                                            <button type="button" className="btn">Submit</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="google-map p-0">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3443.0822068876405!2d78.0693667!3d30.348613500000003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39092b7cce83684f%3A0xb56f980fe590d69c!2sThe%20Wedding%20Psalm!5e0!3m2!1sen!2sin!4v1689260653238!5m2!1sen!2sin" width="100%" height="450" style={{ border: "0" }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                </div>
            </section>

        </main>
    )
}

export default Contact