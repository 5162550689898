import React from 'react';
import BlogBox from './BlogBox';
import images from '../assets/img/Index';
import HeroImg from './HeroImg';

function Content() {
    return (
        <main>

            <HeroImg id={'wedding-hero'} img={images.Blog} title={'The Wedding Psalm'} tagline={'Blog'} />

            <section id="blog-page" className="blog-page">
                <div className="container">
                    <div className="section-header">
                        <h2>Blog</h2>
                    </div>
                    <div className="row row-cols-1 row-cols-lg-3 justify-content-center g-4">
                        <BlogBox title={'Anupreet and Vibash: A School Love Story Turned Wedding Bliss'} imgUrl={images.Collage7} date={'19 Sep, 2023'} author={'The Wedding Psalm'} />
                    </div>
                    {/* <div className="section-footer">
                        <button type="button" className="btn">Load more</button>
                    </div> */}
                </div>
            </section>

        </main>
    )
}

export default Content