import React from 'react';
import ContentBox from './ContentBox';
import images from '../assets/img/Index';
import HeroImg from './HeroImg';

function Content() {
    return (
        <main>

            <HeroImg id={'wedding-hero'} img={images.BadyShower} title={'The Wedding Psalm'} tagline={'Baby Showers'} />

            <section id="wedding-page" className="wedding-page">
                <div className="container">
                    <div className="section-header">
                        <h2>Baby Showers</h2>
                    </div>
                    <div className="row row-cols-1 row-cols-lg-3 g-4 justify-content-center text-center">
                        <ContentBox title={'Maternity'} imgUrl={images.Maternity1} source={'/baby-shower/maternity'} />
                    </div>
                    {/* <div className="section-footer">
                        <button type="button" className="btn">Load more</button>
                    </div> */}
                </div>
            </section>

        </main>
    )
}

export default Content