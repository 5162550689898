import React from 'react';
import ContentBox from './ContentBox';
import images from '../assets/img/Index';
import HeroImg from './HeroImg';

function About() {
    return (
        <main>

            <HeroImg id={'prewedding-hero'} img={images.Prewedding} title={'The Wedding Psalm'} tagline={'Prewedding'} />

            <section id="prewedding-page" className="prewedding-page">
                <div className="container">
                    <div className="section-header">
                        <h2>Prewedding</h2>
                    </div>
                    <div className="row row-cols-1 row-cols-lg-3 g-4 justify-content-center text-center">
                        <ContentBox title={'Funstyle Preweding'} imgUrl={images.Funstyle1} source={'/prewedding/funstyle-preweding'} />
                        <ContentBox title={'Vibhash'} imgUrl={images.Vibhash1} source={'/prewedding/vibhash'} />
                    </div>
                    {/* <div className="section-footer">
                        <button type="button" className="btn">Load more</button>
                    </div> */}
                </div>
            </section>

        </main>
    )
}

export default About