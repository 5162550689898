import React from 'react';
import images from '../assets/img/Index';
import HeroImg from './HeroImg';

function Event() {
  return (
    <main>

      <HeroImg id={'event-hero'} img={images.Event} title={'The Wedding Psalm'} tagline={'Event'} />

      <section id="event-page" className="event-page">
        <div className="container">
          <div className="section-header">
            <h2>Event</h2>
          </div>
          <div className="row g-5">
            <div className="col-12 col-lg-5">
              <div className="image">
                <img src={images.EventOrganizer} className="img-fluid" alt=""></img>
              </div>
            </div>
            <div className="col-12 col-lg-7 align-self-center">
              <h3>WE CAN CATER TO ALL OF YOUR NEEDS</h3>
              <p>From intimate family gatherings to large corporate affairs, We offer a diverse range of services to cater to your every need. Whether it's a special occasion or an important business event, we strive to create unforgettable experiences through our culinary expertise. Let us know your requirements, and we'll go above and beyond to ensure your event is a memorable success. Don't find your specific event listed? Reach out to us! We're more than happy to provide you with a custom quote tailored to your unique needs.</p>
            </div>
          </div>
          <hr className="my-5" />
          <div className="row row-cols-1 row-cols-lg-3 g-3">
            <div className="col">
              <div className="image">
                <img src={images.Event1} className="img-fluid" alt=""></img>
              </div>
            </div>
            <div className="col">
              <div className="image">
                <img src={images.Event2} className="img-fluid" alt=""></img>
              </div>
            </div>
            <div className="col">
              <div className="image">
                <img src={images.Event3} className="img-fluid" alt=""></img>
              </div>
            </div>
            <div className="col">
              <div className="image">
                <img src={images.Event4} className="img-fluid" alt=""></img>
              </div>
            </div>
            <div className="col">
              <div className="image">
                <img src={images.Event5} className="img-fluid" alt=""></img>
              </div>
            </div>
            <div className="col">
              <div className="image">
                <img src={images.Event6} className="img-fluid" alt=""></img>
              </div>
            </div>
          </div>
          {/* <div className="section-footer">
                        <button type="button" className="btn">Load more</button>
                    </div> */}
        </div>
      </section>

    </main>
  )
}

export default Event