import React, { useState, useEffect } from 'react';
import images from '../assets/img/Index';
import { Link, useLocation } from "react-router-dom";
import $ from 'jquery';
import axios from 'axios';

function Navbar() {
    const [form, setForm] = useState('');

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setForm(values => ({...values, [name]: value }));
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        $("#askQueryBtnSubmit").html('<span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> Loading...');
        axios.post('https://theweddingpsalm.com/api/askquery.php/', form).then(function (response) {
            if (response.data.status === true) {
                $("#askQueryBtnSubmit").html("Submit Your Query");
                $("#askQueryForm").trigger("reset");
                $("#closeAskForm").trigger("click");
                $("#successShowBtn").trigger("click");
                $("#successModal p").text(response.data.message);
            } else {
                $("#askQueryBtnSubmit").html("Submit Your Query");
                $("#askQueryForm").trigger("reset");
                $("#closeAskForm").trigger("click");
                $("#errorShowBtn").trigger("click");
                $("#errorModal p").text(response.data.message);
            }
        });
            
    };

    const handleOffcanvas = () => {
        $("#offcanvasMobile .btn-close").trigger("click");
    }

    let location = useLocation();
    useEffect(() => {
    }, [location]);
    return (
        // Header Start
        <header id="header" className="header">
            <nav className="navbar narbar-first navbar-expand-lg">
                <div className="container-fluid">
                    <Link className="navbar-brand" aria-label="The Wedding Psalm" to="/">
                        <img src={images.Logo} className="img-fluid rounded-circle animate__animated animate__flipInX animate__delay-1s" width="70px" alt="The Wedding Psalm" />
                    </Link>
                    <button type="button" className="btn ms-auto me-3 d-block d-lg-none" data-bs-toggle="modal" data-bs-target="#askQueryModal">Ask Query</button>
                    <i className="fa-solid fa-bars d-flex d-lg-none fs-1" data-bs-toggle="offcanvas" data-bs-target="#offcanvasMobile" aria-controls="offcanvasMobile"></i>
                    <button className="navbar-toggler d-none" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        {/* <ul className="navbar-nav ms-auto">
                            <li className="nav-item" data-aos="fade-down" data-aos-delay="100"><Link className={`nav-link ${location.pathname === "/"?"active":""}`} aria-current="page" to="/">Home</Link></li>
                            <li className="nav-item" data-aos="fade-down" data-aos-delay="200"><Link className={`nav-link ${location.pathname === "/about" ? "active" : ""}`} to="/about">About Us</Link></li>
                            <li className="nav-item" data-aos="fade-down" data-aos-delay="200"><Link className={`nav-link ${location.pathname === "/prewedding" ? "active" : ""}`} to="/prewedding">Prewedding</Link></li>
                            <li className="nav-item" data-aos="fade-down" data-aos-delay="200"><Link className={`nav-link ${location.pathname ==="/wedding"?"active":""}`} to="/wedding">Weddings</Link></li>
                            <li className="nav-item" data-aos="fade-down" data-aos-delay="300"><Link className={`nav-link ${location.pathname ==="/cinematography"?"active":""}`} to="/cinematography">Cinematography</Link></li>
                            <li className="nav-item" data-aos="fade-down" data-aos-delay="400"><Link className={`nav-link ${location.pathname ==="/blog"?"active":""}`} to="/blog">Blog</Link></li>
                            <li className="nav-item" data-aos="fade-down" data-aos-delay="500"><Link className={`nav-link ${location.pathname ==="/contact"?"active":""}`} to="/contact">Contact</Link></li>
                        </ul> */}
                        <ul className="navbar-nav ms-auto">
                            <li className="nav-item" data-aos="fade-down" data-aos-delay="100"><Link className={`nav-link ${location.pathname === "/" ? "active" : ""}`} aria-current="page" to="/">Home</Link></li>
                            <li className="nav-item" data-aos="fade-down" data-aos-delay="200"><Link className={`nav-link ${location.pathname === "/about" ? "active" : ""}`} to="/about">About Us</Link></li>
                            {/* <li className="nav-item service-dropdown">
                                <Link className="nav-link dropdown-toggle" role="button">
                                    Services
                                </Link>
                                <ul className="service-menu">
                                    <li className="photography-dropdown">
                                        <Link className="dropdown-item dropdown-toggle" role="button">
                                            Photography
                                        </Link>
                                        <ul className="photography-menu">
                                            <li><Link className={`dropdown-item ${location.pathname === "/prewedding" ? "active" : ""}`} to="/prewedding">Prewedding</Link></li>
                                            <li><Link className={`dropdown-item ${location.pathname === "/wedding" ? "active" : ""}`} to="/wedding">Weddings</Link></li>
                                            <li><Link className={`dropdown-item ${location.pathname === "/baby-showers" ? "active" : ""}`} to="/baby-showers">Baby showers</Link></li>
                                            <li><Link className={`dropdown-item ${location.pathname === "/birthday" ? "active" : ""}`} to="/birthday">Birthdays</Link></li>
                                            <li><Link className={`dropdown-item ${location.pathname === "/cinematography" ? "active" : ""}`} to="/cinematography">Cinematography</Link></li>
                                        </ul>
                                    </li>
                                    <li><Link className={`dropdown-item ${location.pathname === "/event" ? "active" : ""}`} to="/event" role="button">Event</Link></li>
                                    <li><Link className={`dropdown-item ${location.pathname === "/gift" ? "active" : ""}`} to="/gift" role="button">Gift</Link></li>
                                </ul>
                            </li> */}
                            <li className="nav-item" data-aos="fade-down" data-aos-delay="400"><Link className={`nav-link ${location.pathname === "/blog" ? "active" : ""}`} to="/blog">Blog</Link></li>
                            <li className="nav-item" data-aos="fade-down" data-aos-delay="500"><Link className={`nav-link ${location.pathname === "/contact" ? "active" : ""}`} to="/contact">Contact</Link></li>
                        </ul>
                        <button type="button" className="btn d-none d-lg-block" data-bs-toggle="modal" data-bs-target="#askQueryModal">Ask Query</button>
                    </div>
                </div>
            </nav>

            <nav className="navbar navbar-second navbar-expand-lg d-none d-lg-block">
                <div className="container-fluid">
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#secondNavbar" aria-controls="secondNavbar" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="secondNavbar">
                        <ul className="navbar-nav">
                            <li className="nav-item"><Link className={`nav-link ${location.pathname === "/wedding" ? "active" : ""}`} aria-current="page" to="/wedding">Wedding</Link></li>
                            <li className="nav-item"><Link className={`nav-link ${location.pathname === "/prewedding" ? "active" : ""}`} aria-current="page" to="/prewedding">Prewedding</Link></li>
                            <li className="nav-item"><Link className={`nav-link ${location.pathname === "/baby-shower" ? "active" : ""}`} aria-current="page" to="/baby-shower">Baby shower</Link></li>
                            <li className="nav-item"><Link className={`nav-link ${location.pathname === "/birthday" ? "active" : ""}`} aria-current="page" to="/birthday">Birthday</Link></li>
                            <li className="nav-item"><Link className={`nav-link ${location.pathname === "/cinematography" ? "active" : ""}`} aria-current="page" to="/cinematography">Cinematography</Link></li>
                            <li className="nav-item"><Link className={`nav-link ${location.pathname === "/event" ? "active" : ""}`} aria-current="page" to="/event">Event</Link></li>
                            <li className="nav-item"><Link className={`nav-link ${location.pathname === "/gift" ? "active" : ""}`} aria-current="page" to="/gift">Gift</Link></li>
                            <li className="nav-item"><Link className={`nav-link ${location.pathname === "/catering" ? "active" : ""}`} aria-current="page" to="/catering">Catering</Link></li>
                        </ul>
                    </div>
                </div>
            </nav>

            {/* Mobile Offcanvas */}
            <div className="offcanvas offcanvas-start w-auto" tabIndex="-1" id="offcanvasMobile" aria-labelledby="offcanvasMobileLabel">
                <div className="offcanvas-header">
                    <img src={images.Logo} className="img-fluid rounded-circle" width="65px" alt="The Wedding Psalm" />
                    <button type="button" className="btn-close me-1" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body">
                    <ul className="nav">
                        <li className="nav-item"><Link onClick={handleOffcanvas} className={`nav-link ${location.pathname === "/" ? "active" : ""}`} aria-current="page" to="/">Home</Link></li>
                        <li className="nav-item"><Link onClick={handleOffcanvas} className={`nav-link ${location.pathname === "/about" ? "active" : ""}`} to="/about">About Us</Link></li>
                        <li className="nav-item"><Link onClick={handleOffcanvas} className={`nav-link ${location.pathname === "/wedding" ? "active" : ""}`} to="/wedding">Weddings</Link></li>
                        <li className="nav-item"><Link onClick={handleOffcanvas} className={`nav-link ${location.pathname === "/prewedding" ? "active" : ""}`} to="/prewedding">Prewedding</Link></li>
                        <li className="nav-item"><Link onClick={handleOffcanvas} className={`nav-link ${location.pathname === "/baby-shower" ? "active" : ""}`} to="/baby-shower">Baby shower</Link></li>
                        <li className="nav-item"><Link onClick={handleOffcanvas} className={`nav-link ${location.pathname === "/birthday" ? "active" : ""}`} to="/birthday">Birthday</Link></li>
                        <li className="nav-item"><Link onClick={handleOffcanvas} className={`nav-link ${location.pathname === "/cinematography" ? "active" : ""}`} to="/cinematography">Cinematography</Link></li>
                        <li className="nav-item"><Link onClick={handleOffcanvas} className={`nav-link ${location.pathname === "/event" ? "active" : ""}`} to="/event">Event</Link></li>
                        <li className="nav-item"><Link onClick={handleOffcanvas} className={`nav-link ${location.pathname === "/gift" ? "active" : ""}`} to="/gift">Gift</Link></li>
                        <li className="nav-item"><Link onClick={handleOffcanvas} className={`nav-link ${location.pathname === "/catering" ? "active" : ""}`} to="/catering">Catering</Link></li>
                        <li className="nav-item"><Link onClick={handleOffcanvas} className={`nav-link ${location.pathname === "/blog" ? "active" : ""}`} to="/blog">Blog</Link></li>
                        <li className="nav-item"><Link onClick={handleOffcanvas} className={`nav-link ${location.pathname === "/contact" ? "active" : ""}`} to="/contact">Contact</Link></li>
                    </ul>
                </div>
            </div>

            {/* Query Modal */}
            <div className="modal fade askQueryModal" id="askQueryModal" tabIndex="-1" aria-labelledby="askQueryModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="askQueryModalLabel">Ask Your Query</h1>
                            <button type="button" className="btn" data-bs-dismiss="modal" aria-label="Close" id="closeAskForm"><i className="fa-solid fa-xmark"></i></button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleSubmit} id="askQueryForm">
                                <div className="row row-cols-1 g-3 px-2 py-4">
                                    <div className="col">
                                        <div className="form-floating">
                                            <input type="text" className="form-control" id="mname" placeholder="Name" name="mname" onChange={handleChange} required />
                                            <label htmlFor="mname">Name</label>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="form-floating">
                                            <input type="email" className="form-control" id="memail" placeholder="Email address" name="memail" onChange={handleChange} required />
                                            <label htmlFor="memail">Email address</label>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="form-floating">
                                            <input type="tel" className="form-control" id="mphone" placeholder="Phone" name="mphone" onChange={handleChange} required />
                                            <label htmlFor="mphone">Phone</label>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="form-floating">
                                            <textarea className="form-control" placeholder="Message" id="mmessage" style={{ height: "150px" }} name="mmessage" onChange={handleChange} required ></textarea>
                                            <label htmlFor="mmessage">Message</label>
                                        </div>
                                    </div>
                                    <div className="col text-center d-grid">
                                        <button type="submit" className="btn" id="askQueryBtnSubmit">Submit Your Query</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Navbar